import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { styled, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { decodeTrademarkEntity } from '@helpers/string-helper'
import useReviewSubmission from '@hooks/useReviewSubmission'
import { fetchProductBySku } from '@services/product'
import Layout from '@components/layout'
import ReviewProductInfo from '@components/review-product/ReviewProductInfo'
import ReviewForm from '@components/review-product/ReviewForm'
import ReviewProductSuccess from '@components/review-product/ReviewProductSuccess'
import ReviewProductErrorModal from '@components/review-product/ReviewProductErrorModal'
import { decodeJWT, formatTurntoJWTPayload } from '@components/product/ProductDetail/helpers'

const ReviewWrapper = styled(Grid)(({ theme }) => ({
  alignContent: 'center',
  backgroundColor: theme.palette.common.white,
  paddingBottom: 32,
  [theme.breakpoints.down('md')]: { padding: 16 },
  [theme.breakpoints.down('sm')]: { padding: 0 },
}))

const ReviewHeading = styled(Typography)(({ theme }) => ({
  fontSize: 19,
  fontWeight: 400,
  lineHeight: '26px',
  margin: '24px 0px 16px 0px',
  paddingLeft: 16,
  textTransform: 'uppercase',
  [theme.breakpoints.down('md')]: { margin: '16px 0px' },
}))

const ReviewProduct = ({ location }) => {
  // Parse the url search string to get the SKU and other TurnTo info
  const searchParams = Object.fromEntries(new URLSearchParams(location.search))

  const [reviewStatus, setReviewStatus] = useState('')
  const [product, setProduct] = useState(null)
  const [pastOrders, setPastOrders] = useState(null)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const theme = useTheme()
  const [requestUrl, setUrl] = useState('')
  const [options, setOptions] = useState(null)

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const dataTestIdPrefix = isMobile ? 'mobile-review-' : 'desktop-review-'

  const prodSKU = searchParams?.turntosku ?? ''
  const prodImageURL = product?.imageURL ?? ''
  const prodTitle = product?.title ? decodeTrademarkEntity(product.title) : ''
  const turntoJWT = searchParams?.turntoNotificationData
  const turntoJWTPayload = decodeJWT(turntoJWT)
  const { validationErrors, alreadyReviewed, ugcId, email } = formatTurntoJWTPayload(turntoJWTPayload)
  const { data: reviewData, isLoading } = useReviewSubmission(requestUrl, options)

  useEffect(() => {
    const fetchUnreviewedProducts = async userEmail => {
      const response = await fetch(`${process.env.GATSBY_PRODUCT_REVIEWS}/v1/unreviewed/${userEmail}`)
      const data = await response.json()
      setPastOrders(data?.purchasedProducts)
    }

    if ((alreadyReviewed && !validationErrors) || (ugcId && !validationErrors)) {
      setReviewStatus('success')
      if (email) {
        fetchUnreviewedProducts(email)
      }
    } else if (validationErrors === true) {
      setReviewStatus('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyReviewed, validationErrors])

  useEffect(() => {
    if (reviewData?.status === 200) {
      setPastOrders(reviewData?.unreviewedItems?.purchasedProducts)
      setReviewStatus('success')
    } else if (reviewData?.status) {
      try {
        console.error(reviewData?.error?.message || reviewData?.message || reviewData[0]?.message || 'unknown error')
        setErrorText(reviewData?.error?.message)
        setReviewStatus('error')
      } catch {
        console.error('HTTP Error: ', reviewData?.status)
        setReviewStatus('error')
      }
    }
  }, [reviewData, requestUrl, options])

  useEffect(() => {
    const getProduct = async sku => {
      const productData = await fetchProductBySku(sku)
      setProduct({ sku, title: productData.title, imageURL: productData.primary_image })
    }

    if (prodSKU) {
      getProduct(prodSKU)
    }
  }, [prodSKU])

  useEffect(() => {
    if (reviewStatus === 'error') {
      setIsErrorModalOpen(true)
    } else {
      setIsErrorModalOpen(false)
    }
  }, [reviewStatus, setIsErrorModalOpen])

  const handleCloseErrorModal = () => setReviewStatus('')

  // see comments at EOF for handleSubmit API response examples
  const handleSubmit = review => {
    const turntoNotificationData = searchParams?.turntoNotificationData
    if (!turntoNotificationData) {
      setReviewStatus('error') // prevent submitting review without token
    } else {
      const url = `${process.env.GATSBY_PRODUCT_REVIEWS}/v1`
      const body = { ...review, catalogItems: [{ sku: prodSKU }], turntoNotificationData }

      const opts = {
        body: JSON.stringify(body),
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
      }
      setUrl(url)
      setOptions(opts)
    }
  }

  if (typeof window !== 'undefined') {
    if (performance.getEntriesByType('navigation')[0].type === 'reload') {
      // getEntriesByType('navigation') returns array of performance entry events with
      // entryType = 'navigation', which are PerformanceNavigationTiming objects that have
      // a type value of "navigate", "reload", "back_forward" or "prerender".
      // SO, here, we are resetting the URL if the user reloads the page
      const { origin, pathname, search } = location
      window.location.replace(`${origin}${pathname}${search}`)
    }
  }

  if (!prodSKU) return <Layout />

  if (reviewStatus === 'success') {
    return (
      <Layout>
        <ReviewProductSuccess
          isLoading={isLoading}
          pastOrders={pastOrders}
          searchParams={searchParams}
          dataTestIdPrefix={dataTestIdPrefix}
          prodImageURL={prodImageURL}
          prodSKU={prodSKU}
          prodTitle={prodTitle}
        />
      </Layout>
    )
  }

  return (
    <Layout>
      <ReviewWrapper container direction="column">
        <ReviewHeading>Please Review Your Purchase</ReviewHeading>
        <Stack direction={{ sm: 'column', md: 'row' }}>
          <ReviewProductInfo
            showYourOrder
            dataTestIdPrefix={dataTestIdPrefix}
            prodImageURL={prodImageURL}
            prodSKU={prodSKU}
            prodTitle={prodTitle}
          />
          <ReviewForm isLoading={isLoading} dataTestIdPrefix={dataTestIdPrefix} handleSubmit={handleSubmit} />
        </Stack>
      </ReviewWrapper>
      <ReviewProductErrorModal
        errorText={errorText}
        dataTestIdPrefix={dataTestIdPrefix}
        isModalOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
    </Layout>
  )
}

ReviewProduct.propTypes = {
  location: PropTypes.object,
}

export default ReviewProduct

/*
handleSubmit API response examples:

400 error response for invalid SKU:
  {
      "message": "No catalog item found for sku (9945343677)"
  }

500 error response when user has already submitted a review:
  {
      "message": "User already submitted review for this SKU"
  }


Example 200 response for a successfully submitted review:
  {
    "id": 8019,
    "rating": 5,
    "title": "Great sofa",
    "text": "Nice addition to our room",
    "textLength": 25,
    "locale": "en_US",
    "csFlag": false,
    "inappropriateFlag": false,
    "reviewedFlag": false,
    "autoModerated": true,
    "published": true,
    "upVotes": 0,
    "downVotes": 0,
    "incentivized": false,
    "orderId": null,
    "catalogItems": [
        {
            "sku": "10124802",
            "title": "Amalie Teal Sofa",
            "url": "https://www.roomstogo.com/furniture/product/amalie-teal-sofa/10124802",
            "category": "Livingroom",
            "reviewCount": 1,
            "ratingCount": 3,
            "averageRating": 5,
            "ratingBreakdown": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 3
            },
            "attributes": [
                {
                    "type": "productGroup",
                    "typeLabel": "productGroup",
                    "values": [
                        {
                            "value": "Sofas",
                            "valueLabel": "Sofas"
                        }
                    ]
                },
                {
                    "type": "color",
                    "typeLabel": "color",
                    "values": [
                        {
                            "value": "teal",
                            "valueLabel": "teal"
                        }
                    ]
                },
                {
                    "type": "collection",
                    "typeLabel": "collection",
                    "values": [
                        {
                            "value": "Amalie",
                            "valueLabel": "Amalie"
                        }
                    ]
                }
            ],
            "active": true
        }
    ],
    "dimensions": [],
    "media": null,
    "responses": [],
    "reviewedDate": null,
    "purchaseDate": null,
    "dateCreated": "2023-04-07T15:20:38-04:00",
    "user": {
        "nickName": null,
        "firstName": "John",
        "lastName": "Doe",
        "emailAddress": "johndoe@test.com",
        "externalId": null,
        "city": null,
        "state": null,
        "country": null,
        "ageRange": null,
        "badge": null,
        "shopperProfiles": []
    },
    "incentiveType": null,
    "campaign": null,
    "deviceFingerprint": null,
    "customData": [],
    "tags": []
  }
*/
